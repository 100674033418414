<template>
  <div id="product-details">
    <br>
    <v-row>
      <v-col cols="12" lg="8">
        <div v-if="loading" class="d-flex justify-center align-center">
          <Spinner :size="30" />
        </div>
        <ImageCarousel
          v-else-if="images.length > 0"
          :images="images"
          :fullscreen="fullscreen"
          @setFullscreen="setFullscreen"
        />
        <DefaultImage
          v-else
          :height="imageHeight"
        />

        <div class="mt-5" v-if="!$vuetify.breakpoint.lgAndUp">
          <SubscriptionDetails
            v-if="investorSubscriptionInformation"
            :investorSubscriptionInformation="investorSubscriptionInformation"
            :product="product"
            :investLoading="investLoading"
            @invest="invest"
          />
          <div v-else class="d-flex justify-center align-center">
            <Spinner :size="30" />
          </div>
        </div>

        <!-- MORE DETAILS -->
        <div class="mt-5 more-details">
          <h2 v-html="$t('newDeals.productInfo.moreDetails.titleOne')" class="align-left" />
          <p class="mt-5" v-html="$t('newDeals.productInfo.moreDetails.descriptionOne')" />
          <p class="mt-5" v-html="$t('newDeals.productInfo.moreDetails.descriptionTwo')" />
          <p class="mt-5" v-html="$t('newDeals.productInfo.moreDetails.descriptionThree')" />
          <v-expansion-panels
            accordion
            v-model="panel"
            multiple
          >
            <v-expansion-panel :readonly="false">
              <v-expansion-panel-header color="backgroundDark" class="px-0" :disable-icon-rotate="false">
                <h2 v-html="$t('newDeals.productInfo.moreDetails.titleTwo')" />
                <template v-slot:actions v-if="false">
                  <v-icon class="icon">mdi-lock</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content color="backgroundDark" class="px-0">
                <v-row class="mt-5 align-left small-text">
                  <v-col cols="12" sm="6" md="3" lg="6" xl="3" class="mb-5">
                    <v-row>
                      <v-col class="font-weight-medium big-text">Das Auto</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Marke</v-col>
                      <v-col>Mercedes-Benz</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Modell</v-col>
                      <v-col>300 SL Coupé</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Baureihe</v-col>
                      <v-col>W198 (08/1954 - 05/1957)</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Karosserie</v-col>
                      <v-col>Coupé</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Limitierung</v-col>
                      <v-col>76/1400 (DE)</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Baujahr</v-col>
                      <v-col>1954</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Erstzulassung</v-col>
                      <v-col>12.10.1954 in DE</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Aktueller Standort</v-col>
                      <v-col>München, Deutschland</v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" lg="6" xl="3" class="mb-5">
                    <v-row>
                      <v-col class="font-weight-medium big-text">Technische Daten</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Motor</v-col>
                      <v-col>3 L</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Zylinderzahl</v-col>
                      <v-col>6 Zylinder Reihenmotor</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Hubraum in ccm</v-col>
                      <v-col>2996</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Leistung</v-col>
                      <v-col>215 PS (158 kW) bei 5800 U/min</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">VMax in km/h</v-col>
                      <v-col>260</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">0-100 km/h</v-col>
                      <v-col>10,0 s</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Getriebe</v-col>
                      <v-col>4-Gang Schaltgetriebe</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Treibstoffart</v-col>
                      <v-col>Super Benzin</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Türen / Sitzplätze</v-col>
                      <v-col>2 / 2</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Position des Lenkrads</v-col>
                      <v-col>links</v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" lg="6" xl="3" class="mb-5">
                    <v-row>
                      <v-col class="font-weight-medium big-text">Zustand</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Grade* (A,B,C)</v-col>
                      <v-col>A</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Baujahr</v-col>
                      <v-col>1954</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Kilometerstand in km</v-col>
                      <v-col>34.765</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Matching Numbers</v-col>
                      <v-col><v-icon size="12" class="ml-1 mt-1">mdi-check</v-icon></v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" lg="6" xl="3" class="mb-5">
                    <v-row>
                      <v-col class="font-weight-medium big-text">Farbe & Materialien</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Farbe innen</v-col>
                      <v-col>dunkelgrün</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Farbe aussen</v-col>
                      <v-col>grau</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Interieur</v-col>
                      <v-col>Leder dunkelgrün</v-col>
                    </v-row>
                    <template v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.xlOnly">
                      <v-row>
                        <v-col class="font-weight-medium big-text">Modifikationen</v-col>
                      </v-row>
                      <v-row>
                        <v-col class="grey--text">Verdeck</v-col>
                        <v-col>neu</v-col>
                      </v-row>
                      <v-row>
                        <v-col class="grey--text">Modifikation</v-col>
                        <v-col>Mercedes Benz 300 SL Son</v-col>
                      </v-row>
                    </template>
                  </v-col>

                  <v-col
                    v-if="$vuetify.breakpoint.smAndDown || $vuetify.breakpoint.lgOnly"
                    cols="12" sm="6" md="3" lg="6" xl="3"
                    class="mb-5"
                  >
                    <v-row>
                      <v-col class="font-weight-medium big-text">Modifikationen</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Verdeck</v-col>
                      <v-col>neu</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Modifikation</v-col>
                      <v-col>Mercedes Benz 300 SL Son</v-col>
                    </v-row>
                  </v-col>

                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel :readonly="false">
              <v-expansion-panel-header color="backgroundDark" class="px-0" :disable-icon-rotate="false">
                <h2 v-html="$t('newDeals.productInfo.moreDetails.titleThree')" />
                <template v-slot:actions v-if="false">
                  <v-icon class="icon">mdi-lock</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content color="backgroundDark" class="px-0">
                <p class="mt-5" v-html="$t('newDeals.productInfo.moreDetails.descriptionFour')" />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel :readonly="!onBoardingCompleted">
              <v-expansion-panel-header color="backgroundDark" class="px-0" :disable-icon-rotate="!onBoardingCompleted">
                <h2 v-html="$t('newDeals.productInfo.moreDetails.titleFour')" />
                <template v-slot:actions v-if="!onBoardingCompleted">
                  <v-icon class="icon">mdi-lock</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content color="backgroundDark" class="px-0">
                <p class="mt-5" v-html="$t('newDeals.productInfo.moreDetails.descriptionFive')" />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel :readonly="!onBoardingCompleted">
              <v-expansion-panel-header color="backgroundDark" class="px-0" :disable-icon-rotate="!onBoardingCompleted">
                <h2 v-html="$t('newDeals.productInfo.moreDetails.titleFive')" />
                <template v-slot:actions v-if="!onBoardingCompleted">
                  <v-icon class="icon">mdi-lock</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content color="backgroundDark" class="px-0">
                <p class="mt-5" v-html="$t('newDeals.productInfo.moreDetails.descriptionSix')" />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel :readonly="false">
              <v-expansion-panel-header color="backgroundDark" class="px-0" :disable-icon-rotate="false">
                <h2 v-html="$t('newDeals.productInfo.moreDetails.titleSix')" />
                <template v-slot:actions v-if="false">
                  <v-icon class="icon">mdi-lock</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content color="backgroundDark" class="px-0">
                <p class="mt-5" v-html="$t('newDeals.productInfo.moreDetails.descriptionSeven')" />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel :readonly="false">
              <v-expansion-panel-header color="backgroundDark" class="px-0" :disable-icon-rotate="false">
                <h2 v-html="$t('newDeals.productInfo.moreDetails.titleSeven')" />
                <template v-slot:actions v-if="false">
                  <v-icon class="icon">mdi-lock</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content color="backgroundDark" class="px-0">
                <p class="mt-5" v-html="$t('newDeals.productInfo.moreDetails.descriptionEight')" />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel :readonly="!onBoardingCompleted">
              <v-expansion-panel-header color="backgroundDark" class="px-0" :disable-icon-rotate="!onBoardingCompleted">
                <h2 v-html="$t('newDeals.productInfo.moreDetails.titleEight')" />
                <template v-slot:actions v-if="!onBoardingCompleted">
                  <v-icon class="icon">mdi-lock</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content color="backgroundDark" class="px-0">
                <p class="mt-5" v-html="$t('newDeals.productInfo.moreDetails.descriptionNine')" />
              </v-expansion-panel-content>
            </v-expansion-panel>

          </v-expansion-panels>

          <div class="d-flex mt-3">
            <h2 v-html="$t('newDeals.productInfo.moreDetails.titleNine')" />
            <v-icon v-if="!onBoardingCompleted" class="ml-3">mdi-lock</v-icon>
          </div>
          <div v-if="onBoardingCompleted">
            <div class="mt-5 d-flex flex-column">
              <Document
                v-for="(document, index) in documents"
                :key="index"
                :document="document"
                :index="index"
              />
            </div>
          </div>
          <h2 v-html="$t('newDeals.productInfo.moreDetails.titleTen')" class="mt-5" />
          <p class="mt-5" v-html="$t('newDeals.productInfo.moreDetails.descriptionTen')" />
        </div>
      </v-col>

      <v-col v-if="$vuetify.breakpoint.lgAndUp" lg="4">
        <SubscriptionDetails
          v-if="investorSubscriptionInformation"
          :class="onBoardingCompleted ? 'subscription-details-onboarded' : 'subscription-details'"
          :investorSubscriptionInformation="investorSubscriptionInformation"
          :product="product"
          :investLoading="investLoading"
          @invest="invest"
        />
        <div v-else class="d-flex justify-center align-center">
          <Spinner :size="30" />
        </div>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';
import clone from '@/utils/clone';
import pdpDefaultDocs from '@/config/pdpDefaultDocs';
import Spinner from '@/components/Shared/Spinner.vue';

export default {
  name: 'ProductDetails',
  components: {
    DefaultImage: lazyLoad('components/Assets/DefaultImage'),
    Document: lazyLoad('components/Marketplace/NewDeals/ProductDetails/ProductDocument'),
    ImageCarousel: lazyLoad('components/Marketplace/NewDeals/ProductDetails/ImageCarousel'),
    SubscriptionDetails: lazyLoad('components/Marketplace/NewDeals/ProductDetails/SubscriptionDetails'),
    Spinner,
  },
  props: {
    product: {
      type: Object,
    },
    investorSubscriptionInformation: {
      type: Object,
    },
    investLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      images: [],
      loading: true,
      fullscreen: false,
      onboardingModal: false,
      panel: [],
      documents: clone(pdpDefaultDocs),
    };
  },
  computed: {
    ...mapGetters('user', [
      'onBoardingCompleted',
    ]),
    imageHeight() {
      return this.fullscreen ? 700 : 456;
    },
    productId() {
      return this.$route.params.product;
    },
    assetStatus() {
      return this.product.assetStatus;
    },
  },
  async created() {
    const images = await this.$store.dispatch('assets/getImages', {
      category: 'Supporting',
      assetId: this.product.id,
    });
    if (images.length > 0) {
      this.images = images;
    }
    this.loading = false;
  },
  methods: {
    ...mapActions('onboarding', [
      'setOnboardingModal',
    ]),
    invest() {
      if (this.onBoardingCompleted) {
        this.$emit('invest');
      } else {
        this.setOnboardingModal(true);
      }
    },
    setFullscreen(value) {
      this.fullscreen = value;
    },
  },
};
</script>

<style lang="scss">
#product-details {
  .subscription-details {
    position: fixed;
    overflow-y: scroll;
    top: 122px;
    width: 25%;
    height: 86vh;
  }
  .subscription-details-onboarded {
    position: fixed;
    overflow-y: scroll;
    top: 80px;
    width: 25%;
    height: 86vh;
  }
  .align-right {
    text-align: right;
  }
  .align-left {
    text-align: left;
  }
  .field-desc {
    font-size: 12px;
  }
  .v-divider {
    margin: .5em 0 1em 0;
  }
  .v-slider {
    margin: 0;
  }
  .xs-text {
    font-size: 10px;
  }
  .small-text {
    font-size: 12px;
  }
  .medium-text {
    font-size: 13px;
  }
  .big-text {
    font-size: 16px;
  }
  .v-skeleton-loader__image.v-skeleton-loader__bone {
    height: 400px;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .more-details {
    text-align: justify;
  }
  .tooltip-text {
    font-size: 50px !important;
    opacity: 1 !important;
    display: block !important;
    color: red !important;
  }
  .v-expansion-panel-content__wrap {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .v-expansion-panel-header__icon {
    margin-left: 20px !important;
  }
  .v-expansion-panel-header>:not(.v-expansion-panel-header__icon) {
    flex-grow: 0 !important;
  }
  .v-expansion-panel:not(:first-child):after {
    border-top: 0px !important;
  }
  .v-expansion-panel:before {
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0 !important;
  }
  .paper-icon {
    transform: scale(1.5);
  }
}
</style>
